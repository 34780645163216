import { Table, Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ANALYSIS_LEGEND } from "utils/constants/analysisConstant";
import { DATE_FORMAT } from "utils/constants/index";
import { ReactComponent as LeftMovement } from "./icons/left.svg";
import { ReactComponent as RightMovement } from "./icons/right.svg";
import { ReactComponent as ThrougLeftMovement } from "./icons/through-left.svg";
import { ReactComponent as ThrougRightMovement } from "./icons/through-right.svg";
import { ReactComponent as ThroughMovement } from "./icons/through.svg";

class TableMovement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPercentage: true,
    };
  }
  getLabelColumn = (record) => {
    switch (record.key) {
      case "Left":
        return <LeftMovement />;
      case "Right":
        return <RightMovement />;
      case "Through/Left":
        return <ThrougLeftMovement />;
      case "Through/Right":
        return <ThrougRightMovement />;
      case "Through":
        return <ThroughMovement />;

      default:
        break;
    }
  };
  getTableColumns = () => {
    const listLegend = ANALYSIS_LEGEND.turning_movement;
    const { dataSource } = this.props;

    return [
      {
        title: "Time",
        key: "time",
        width: 120,
        render: (_data, record) =>
          `${moment(record.from_time).format(DATE_FORMAT.hour)}-${moment(
            record.to_time
          ).format(DATE_FORMAT.hour)}`,
        dataIndex: "total",
        className: "text-left",
      },
      ...listLegend.map((legend) => {
        return {
          title: (
            <div className="flex flex-col justify-start items-center">
              <div style={{ width: 20, height: 20 }}>
                {this.getLabelColumn(legend)}{" "}
              </div>

              <span>
                {`${
                  dataSource.movements?.[legend.key]
                    ? `Det-${dataSource.movements[legend.key].join(", ")}`
                    : "-"
                }`}
              </span>
            </div>
          ),
          key: legend.key,
          dataIndex: legend.key,
          render: (data, record) => {
            return data === undefined ? (
              "-"
            ) : (
              <div className="w-full h-full flex flex-col items-center justify-center ">
                <p className="mb-0 w-full white-space-no-wrap text-center">
                  {data}
                </p>
                {record.total ? (
                  <p className="mb-0 w-full font-12 white-space-no-wrap text-center">
                    {`(${((data * 100) / record.total).toFixed(2)}%)`}
                  </p>
                ) : (
                  <Tooltip title={`${data * 100} / ${record.total}`}>
                    <p className="mb-0 w-full font-12 white-space-no-wrap text-center">
                      -
                    </p>
                  </Tooltip>
                )}
              </div>
            );
          },
          // data === undefined
          //   ? "-"
          //   : this.state.showPercentage
          //   ? record.total === 0
          //     ? "-"
          //     : `${((data * 100) / record.total).toFixed(2)}%`
          //   : data,
        };
      }),
      {
        title: "Total Vehicles",
        className: "text-left",
        width: 96,
        key: "total",
        render: (data) => <b>{data}</b>,
        dataIndex: "total",
      },
    ];
  };
  render() {
    const { dataSource, pageSize } = this.props;

    return (
      <>
        <div className=" table-percentage-mode"></div>
        <Table
          className="spm-table"
          columns={this.getTableColumns()}
          dataSource={dataSource?.bins}
          rowKey={"from_time"}
          pagination={{
            pageSize: pageSize,
          }}
        />
      </>
    );
  }
}

export default withTranslation()(TableMovement);
