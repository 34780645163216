export const WIDGET_MENU_ITEMS = {
  SETTING: "widget_setting",
  DELETE_WIDGET: "delete_widget",
};

export const DASHBOARD_WIDGETS = {
  HEATMAP: "map_view_widget",
  TOP_TEN: "top_int_open_alarm_widget",
  INFO_ALARM_MESSAGE: "open_alarm_widget",
  SUMMARY_REPORT: "summary_report_widget",
  VOLUME_COUNT: "volume_report_widget",
  NOTIFICATION: "notification_widget",
};

export const DASHBOARD_WIDGET_IDS = {
  HEATMAP: "map_view",
  TOP_TEN: "top_int_open_alarm",
  INFO_ALARM_MESSAGE: "open_alarm",
  SUMMARY_REPORT: "summary_report",
  VOLUME_COUNT: "volume_report",
  NOTIFICATION: "notification",
};

export const DASHBOARD_DEFAULT_AVAILABLE_WIDGETS = [
  {
    key: DASHBOARD_WIDGETS.HEATMAP,
    name: "dashboard.map-header",
    description: "dashboard.map-description",
    image: "/svg/logo.svg",
  },
  {
    key: DASHBOARD_WIDGETS.TOP_TEN,
    name: "dashboard.top-ten-header",
    description: "dashboard.top-ten-description",
    image: "/svg/logo.svg",
  },
  {
    key: DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE,
    name: "dashboard.chart-header",
    description: "dashboard.chart-description",
    image: "/svg/logo.svg",
  },
  {
    key: DASHBOARD_WIDGETS.SUMMARY_REPORT,
    name: "dashboard.summary-report-header",
    description: "dashboard.summary-report-description",
    image: "/svg/logo.svg",
  },
  {
    key: DASHBOARD_WIDGETS.VOLUME_COUNT,
    name: "dashboard.volume-count-header",
    description: "dashboard.volume-count-description",
    image: "/svg/logo.svg",
  },
  {
    key: DASHBOARD_WIDGETS.NOTIFICATION,
    name: "dashboard.notification-header",
    description: "dashboard.notification-description",
    image: "/svg/logo.svg",
  },
];

export const DASHBOARD_DEFAULT_LAYOUT = [
  {
    i: DASHBOARD_WIDGETS.HEATMAP,
    x: 0,
    y: Infinity,
    w: 17,
    h: 16,
  },
  {
    i: DASHBOARD_WIDGETS.TOP_TEN,
    x: Infinity,
    y: 0,
    w: 7,
    h: 10,
  },
  {
    i: DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE,
    x: Infinity,
    y: 0,
    w: 7,
    h: 6,
  },
  {
    i: DASHBOARD_WIDGETS.SUMMARY_REPORT,
    x: 0,
    y: Infinity,
    w: 17,
    h: 16,
  },
  {
    i: DASHBOARD_WIDGETS.VOLUME_COUNT,
    x: Infinity,
    y: 0,
    w: 7,
    h: 10,
  },
  {
    i: DASHBOARD_WIDGETS.NOTIFICATION,
    x: Infinity,
    y: 0,
    w: 7,
    h: 6,
  },
];

export const TIME_PERIODS = {
  LAST_24_HOURS: "LAST_24_HOURS",
  LAST_12_HOURS: "LAST_12_HOURS",
  CUSTOM: "CUSTOM",
};

export const MAP_PERIODS = [
  { value: TIME_PERIODS.LAST_24_HOURS, label: "map_setting.last_24_hours" },
  { value: TIME_PERIODS.LAST_12_HOURS, label: "map_setting.last_12_hours" },
  { value: TIME_PERIODS.CUSTOM, label: "map_setting.custom" },
];

export const METRIC_DEFAULT = {
  OPEN_ALARMS: "open_alarm_noti_count",
  AOR_PERCENT: "aor_percent",
  AOG_PERCENT: "aog_percent",
  VEHICLE_VOLUMN: "detector_actuation_volume",
  AVG_PED_DELAY: "avg_ped_delay",
  PP: "preemption_priority_requests",
  RLV_COUNT: "red_light_violation_count",
};

export const MAP_METRIC_DEFAULT = [
  { value: METRIC_DEFAULT.OPEN_ALARMS, label: "map_setting.open_alarms" },
  { value: METRIC_DEFAULT.AOR_PERCENT, label: "map_setting.aor_percent" },
  { value: METRIC_DEFAULT.AOG_PERCENT, label: "map_setting.aog_percent" },
  { value: METRIC_DEFAULT.VEHICLE_VOLUMN, label: "map_setting.vehicle_volumn" },
  { value: METRIC_DEFAULT.AVG_PED_DELAY, label: "map_setting.avg_ped_delay" },
  { value: METRIC_DEFAULT.PP, label: "map_setting.pp" },
  { value: METRIC_DEFAULT.RLV_COUNT, label: "map_setting.rlv_count" },
];

export const MAP_WIDGET_DEFAULT_SETTING = {
  changed: false,
  period: TIME_PERIODS.LAST_24_HOURS,
  saveZoomLevel: true,
  zoomLevel: 10,
  centerPointer: { lat: 0, lon: 0 },
  dotSize: 10,
  radius: 10,
  blur: 10,
  defaultMetric: METRIC_DEFAULT.OPEN_ALARMS,
};

export const DASHBOARD_INTERSECTION_SELECTION_TYPE = {
  INCLUDE_ALL: "INCLUDE_ALL",
  INCLUDE_SPECIFIC: "INCLUDE_SPECIFIC",
  EXCLUDE_SPECIFIC: "EXCLUDE_SPECIFIC",
};

export const SELECT_PERIODS_DEFAULT_SETTING = {
  changed: false,
  period: TIME_PERIODS.LAST_24_HOURS,
  timeRange: null,
};

export const SELECT_INTERSECTION_DEFAULT_SETTING = {
  changed: false,
  select_type: DASHBOARD_INTERSECTION_SELECTION_TYPE.INCLUDE_ALL,
  selected_intersections: [],
};

export const DEFAULT_SUMMARY_REPORT_METRICS = [
  "aor_percent",
  "aog_percent",
  "detector_actuation",
  "max_out_percent",
  "gap_out_percent",
  "force_off_percent",
  "avg_ped_delay",
];
